<template>
    <div>
        <top></top>
        <div class="content" v-html="data.cont"></div>
        <bottom></bottom>
    </div>
</template>
<script>
    import Qs from "qs";
    import axios from "axios";
    export default {
        data() {
            return {
                data: '',
            }
        },
        mounted() {
            axios.get(this.http + "/shopmini/wap_Bottom.html").then((res) => {

                this.data = res.data;
            });
        },
    }
</script>
<style scoped>
    .content {
        width: 1192px;
        margin: 80px auto 20px;
    }
</style>